import olumo from '../assets/png/Olumo.png';
import faro from '../assets/png/faro.png';
import ensemble from '../assets/png/ensemble.png';
import cryptoportfolio from '../assets/png/cryptoportfolio.png';

export const projectsData = [
    {
        id: 1,
        projectName: 'Olumo',
        projectDesc: 'One of the first project that I had worked on. Mainly focused on developing features using the ruby on rails framework. It is an employee management system for a company.',
        tags: ['Ruby on rails', 'HTML' ,'CSS', 'Javascript'],
        demo: 'https://www.olumo.com/',
        image: olumo
    },
    {
        id: 2,
        projectName: 'Faro',
        projectDesc: 'An equipment management system which is used by dentists to keep track of their settings and use settings of others automatically when needed.',
        tags: ['Ruby on rails','HTML', 'CSS','Javascript'],
        demo: 'https://faro-nightly.kotukodev.it/',
        image: faro
    },
    {
        id: 3,
        projectName: 'Ensemble',
        projectDesc: 'This project is an event management system for any type of event. It is a web application that allows users to create events and manage them.',
        tags: ['Ruby on rails', 'HTML' ,'CSS', 'Javascript'],
        demo: 'https://www.ensembleapp.com/',
        image: ensemble
    },
    {
        id: 4,
        projectName: 'Crypto Portfolio',
        projectDesc: 'This is a crypto portfolio tracker that you can track your crypto currencies and see the value of your portfolio.',
        tags: ['Ruby on rails', 'HTML' ,'CSS', 'Javascript', 'React'],
        code: 'https://github.com/VolveRe678/cryptoPortfolio',
        demo: 'https://salty-springs-31821.herokuapp.com/',
        image: cryptoportfolio
    }
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/