export const educationData = [
    {
        id: 1,
        institution: 'Maharishi International University',
        course: 'Masters in Computer Science',
        startYear: '2021',
        endYear: 'Present'
    },
    {
        id: 2,
        institution: 'Kantipur Engineering Collage, Kathmandu',
        course: 'Bachelors in Computer Engineering',
        startYear: '2015',
        endYear: '2019'
    },
    {
        id: 3,
        institution: 'St Xavier\'s School, Kathmandu',
        course: 'High School'
    },
]