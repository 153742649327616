export const socialsData = {
    github: 'https://github.com/VolveRe678',
    facebook: 'https://www.facebook.com/sahil.maharjan678/',
    linkedIn: 'https://www.linkedin.com/in/sahil-maharjan-67565215b/',
    instagram: 'https://www.instagram.com/sahilz678/?hl=en',
    codepen: '',
    twitter: '',
    reddit: '',
    blogger: '',
    medium: 'https://medium.com/@sreerag.rajan5',
    stackOverflow: 'https://stackoverflow.com/users/11549860/sahil-z-maharjan',
    gitlab: '',
    youtube: ''
}